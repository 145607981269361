.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.main-content {
  flex-grow: 1;
  padding: 1rem 0;
}
