@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import "../node_modules/bulma/bulma.sass";

:root {
  --dark: #2f4f4f;
  --rosy: #bc8f8f;
  --coral: #f08080;
  --gold: #ffd700;
  --green: #daf7a6;
  --dark-green: rgb(46, 139, 87);
  --grey: #ccc;
}

#root,
.app {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  line-height: 1.42;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: var(--dark);
  padding: 0;
  margin: 0;
}

ul,
p {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.wrapper {
  flex-grow: 1;
  position: relative;
}

.hide {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
