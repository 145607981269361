.snake-game {
  display: flex;
  max-width: max-content;
  padding: 2rem;
  margin: 0 auto;
}

.game-area {
  position: relative;
  width: 600px;
  height: 600px;
  border: 4px solid var(--dark);
  border-radius: 0.25rem;
  background-color: var(--green);
}

.snake-dot {
  position: absolute;
  z-index: 2;
  width: 2%;
  height: 2%;
  background-color: var(--dark);
  border: 1px solid var(--green);
  &:last-child {
    background-color: var(--gold);
    border-color: var(--dark);
    border-radius: 0.25rem;
    &::after {
      content: "";
      display: block;
      width: 3px;
      height: 3px;
      background-color: white;
      margin-left: 4px;
      margin-top: 4px;
      border-radius: 2px;
    }
  }
}

.snake-food {
  position: absolute;
  z-index: 1;
  width: 2%;
  height: 2%;
  background-color: var(--coral);
  border: 1px solid var(--green);
  border-radius: 0.25rem;
}

.stats {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid var(--dark);
  border-radius: 0.25rem;
  h3 {
    margin-right: 1rem;
    color: var(--dark);
  }
  button {
    margin-right: 1rem;
    background-color: var(--dark);
    color: var(--green);
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: all 333ms ease;
    &:disabled {
      opacity: 0.5;
    }
  }
}

.game-info {
  padding-left: 2rem;
  color: var(--dark);
}

.game-rules {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  h5 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  li {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
    position: relative;
    &::before {
      content: "-";
      display: inline;
      font-weight: 700;
      margin-right: 0.5rem;
    }
  }
}

.alert {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid var(--dark);
  color: var(--gredarken);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  transition: all 333ms;
  p {
    margin: 0;
  }
}

.alert-coral {
  border-color: var(--coral);
  color: var(--coral);
}

.link {
  display: inline-block;
  color: var(--dark);
  transition: all 333ms ease;
  font-weight: 700;
  margin-top: 1rem;
  text-decoration: none;
  &:hover {
    color: var(--dark);
    opacity: 0.8;
  }
}
