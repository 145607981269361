.modal {
  display: block;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}
.modal div {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
.modal .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
