.row {
  display: flex;
  justify-content: center;
  text-align: center;
  div {
    display: block;
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--dark);
    margin: 0.25rem;
    line-height: 4rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5em;
    font-weight: 700;
  }

  .grey {
    --wordle-tiles-background-color: var(--grey);
    --wordle-tiles-border-color: var(--grey);
    --wordle-tiles-color: var(--dark);
    opacity: 0.5;
    animation: flip 0.5s ease forwards;
  }

  .green {
    --wordle-tiles-background-color: var(--dark-green);
    --wordle-tiles-border-color: var(--dark-green);
    --wordle-tiles-color: white;
    animation: flip 0.5s ease forwards;
  }

  .yellow {
    --wordle-tiles-background-color: var(--gold);
    --wordle-tiles-border-color: var(--gold);
    --wordle-tiles-color: var(--dark);
    animation: flip 0.5s ease forwards;
  }
}

.row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.row > div:nth-child(3) {
  animation-delay: 0.4s;
}
.row > div:nth-child(4) {
  animation-delay: 0.6s;
}
.row > div:nth-child(5) {
  animation-delay: 0.8s;
}

.row {
  .filled {
    animation-delay: 0s !important;
    animation: bounce 0.2s ease-in-out forwards;
  }
}

// animations
@keyframes flip {
  0% {
    transform: rotateX(0deg);
    background-color: white;
    border-color: var(--wordle-tiles-dark);
  }
  45% {
    transform: rotateX(90deg);
    background-color: white;
    border-color: var(--wordle-tiles-dark);
  }
  55% {
    transform: rotateX(90deg);
    background: var(--wordle-tiles-background-color);
    border-color: var(--wordle-tiles-border-color);
    color: var(--wordle-tiles-color);
  }
  100% {
    transform: rotateX(0deg);
    background: var(--wordle-tiles-background-color);
    border-color: var(--wordle-tiles-border-color);
    color: var(--wordle-tiles-color);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
